var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "8",
      "md": "4"
    }
  }, [_c('v-row', {
    staticClass: "mt-10 mb-10",
    attrs: {
      "justify": "center"
    }
  }, [_c('h1', [_vm._v("AOF KursAdmin")])]), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-sheet', {
    attrs: {
      "rounded": "lg"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "title font-weight-light text-center"
  }, [_vm._v("Logg inn i universum")]), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "align": "center"
    }
  }, [_c('v-btn', {
    attrs: {
      "dark": "",
      "color": "primary",
      "data-cy": "sign-in-ad-btn"
    },
    on: {
      "click": _vm.loginAofNorge
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-microsoft")]), _vm._v(" Logg inn med AOF Norge eller AOF Østfold")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "align": "center"
    }
  }, [_c('v-btn', {
    attrs: {
      "dark": "",
      "color": "primary",
      "data-cy": "sign-in-ad-btn"
    },
    on: {
      "click": _vm.loginAofHaugland
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-microsoft")]), _vm._v(" Logg inn med AOF Vestlandet-Agder")], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }